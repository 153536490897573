import * as React from "react"
import { useEffect } from "react"
import Layout from "../../../components/v2/layout"
import Seo from "../../../components/seo"

import { connect } from "react-redux"
import { useStaticQuery, graphql} from "gatsby"

import {getTagsAction, getNoveltiesAction, getFiltersNoveltiesAction, clearNoveltyAction} from "../../../redux/noveltiesDucks"

//Components
import Main from '../../../components/v2/Novedades/main'
import Searcher from '../../../components/v2/Novedades/searcher.jsx'
import Featured from "../../../components/v2/Novedades/featured"
import Grid from "../../../components/v2/Novedades/grid"



const IndexPage = ({novelties,tags,dispatch}) => {
  const { realEstate } = useStaticQuery(graphql`
    query {
        realEstate {
            name
            env { 
              APISERVER
              TENANTID
              CLIENTID
            }
        }
  }`)
useEffect(() => {
    const { env } = realEstate
  },[])

  return(
  <Layout>
    <Seo title="Novedades" description="Más de 150 años de experiencia en el sector rural. Conozca los campos más destacados de la Argentina, servicio de tasación satelital, divisiones y consultoría."/>
    <Main />
    {/* <Searcher /> */}
    <Grid />
  </Layout>
)
}

export default connect(state => ({
  novelties: state.novelties.novelties,
  tags: state.novelties.tags
}),null)(IndexPage);
