import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import "../../../../node_modules/animate.css/animate.css"
import {
  clearFiltersAction,
  clearNoveltiesAction,
  clearNoveltyAction,
  getFiltersNoveltiesAction,
  updateFiltersNovelties,
} from "../../../redux/noveltiesDucks"
import { graphql, useStaticQuery } from "gatsby"
import Breadcrumbs from "../breadcrumbs"
import { useLocation } from "@reach/router"

const Searcher = ({
  loading_novelties,
  loading_grid,
  loading_tags,
  filtersNovelties,
  novelties,
  grid_novelties,
  tags,
  dispatch,
}) => {
  const { realEstate } = useStaticQuery(graphql`
    query {
      realEstate {
        name
        env {
          APISERVER
          TENANTID
          CLIENTID
        }
      }
    }
  `)

  const { env } = realEstate

  const [listActives, setListActive] = useState([])
  const location = useLocation()

  const toggleTag = id => {
    if (listActives.find(e => e === id)) {
      return setListActive(listActives.filter(e => e !== id))
    }
    return setListActive([...listActives, id])
  }

  useEffect(() => {
    dispatch(updateFiltersNovelties(listActives))
  }, [listActives])


  return (
    <section className="buscador-novedades">
      <div className="container-fluid">
        <div className="d-flex justify-content-center w-100 mb-5 pb-lg-5">
          <Breadcrumbs
            props={[
              { name: "Bullrich Campos", route: "/", location: "" },
              { name: "Novedades", route: "", location: "" },
            ]}
          />
        </div>
        <p className="text-center mb-lg-5 pb-lg-5">
          {" "}
          <div className="first">E</div>n nuestra sección de novedades,
          brindamos información actualizada con lo último en <br /> tendencias y
          noticias del sector inmobiliario rural en Argentina y el resto mundo.
        </p>
        <div className={" row " + ((tags.filter(tag => tag.name.toLowerCase() !== 'circular').length === 0  || novelties.filter(n => n.featured === false && !n.tags.find(element => element.name.toLowerCase() === 'circular')).length === 0) && 'd-none')}>
          <div className="col-lg-12 d-flex flex-column-reverse flex-lg-row align-items-start">
            <div className="tags">
              {tags.filter(tag => tag.name.toLowerCase() !== 'circular').map((tag, index) =>
                listActives.find(e => e === tag.id) ? (
                  ""
                ) : (
                  <span
                    key={index}
                    onClick={() => toggleTag(tag.id)}
                    className="animate__animated animate__fadeInRight cursor-pointer pill tag"
                  >
                    {tag.name}
                  </span>
                )
              )}
            </div>
            <div className="searcher d-flex align-items-end">
              <div className="content-actives">
                {listActives.map((tag, index) => (
                  <span
                    key={index}
                    onClick={() => toggleTag(tag)}
                    className="animate__animated animate__fadeInRight cursor-pointer pill tag"
                  >
                    {tags.find(element => element.id === tag).name}
                  </span>
                ))}
              </div>
              <div onClick={() => dispatch(getFiltersNoveltiesAction(env, true))} className="btn btn-white border">FILTRAR</div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default connect(
  state => ({
    loading_novelties: state.novelties.loading_novelties,
    loading_grid: state.novelties.loading_grid,
    loading_tags: state.novelties.loading_tags,
    filtersNovelties: state.novelties.filtersNovelties,
    novelties: state.novelties.novelties,
    grid_novelties: state.novelties.grid_novelties,
    tags: state.novelties.tags,
  }),
  null
)(Searcher)
