import React from 'react';
import { connect } from 'react-redux';
import '../../../../node_modules/animate.css/animate.css';
import Image from '../../../images/novedades-top.webp'


const Main = () => {

    return(
        <section className="banner-top novedades">
            <img src={Image} className="video" alt="Imagen de portada novedades"/>
            <div className="content text-center">
            <h1 className="white">Le acercamos las últimas <br /> novedades del mercado</h1>
            </div>
      </section>
    )
} 

export default connect(state => ({
  }),null)(Main);