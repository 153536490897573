import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import "../../../../node_modules/animate.css/animate.css"
import Image from "../../../images/novedades-top.png"
import NoveltyCard from "../novelty-card"
import Featured from "./featured"
import { useGetBlogsQuery } from "../../../redux/mediacore/blog"
import Breadcrumbs from "../breadcrumbs"

const Grid = () => {

  const { data: allBlogData, isLoading, isError } = useGetBlogsQuery(26);

  const [filteredNovelties,setFilteredNovelties] = useState([])
  const [listActives, setListActive] = useState([])

  const toggleTag = id => {
    if (listActives.find(e => e === id)) {
      return setListActive(listActives.filter(e => e !== id))
    }
    return setListActive([...listActives, id])
  }

  useEffect(() => {
      setFilteredNovelties(allBlogData?.news)
  },[allBlogData])

  const filterNoveltiesAction = () => {
      let response = []
      if(allBlogData?.news){
          if(listActives.length === 0){return setFilteredNovelties(allBlogData?.news)}
          for (const novelty of allBlogData?.news) {
              if(novelty.tags.find(t => listActives.find(l => l === t.id))){
                  response.push(novelty);
              }
          }
      }
      return setFilteredNovelties(response)
  }

  return (
    <>
        <section className="buscador-novedades">
          <div className="container-fluid">
            <div className="d-flex justify-content-center w-100 mb-5 pb-lg-5">
              <Breadcrumbs
                props={[
                  { name: "Bullrich Campos", route: "/", location: "" },
                  { name: "Novedades", route: "", location: "" },
                ]}
              />
            </div>
            <p className="text-center mb-lg-5 pb-lg-5">
              {" "}
              <div className="first">E</div>n nuestra sección de novedades,
              brindamos información actualizada con lo último en <br /> tendencias y
              noticias del sector inmobiliario rural en Argentina y el resto mundo.
            </p>
            <div className={" row " + ((allBlogData?.tags?.filter(tag => tag.name.toLowerCase() !== 'circular').length === 0  || allBlogData?.news?.filter(n => n.featured === false && !n.tags.find(element => element.name.toLowerCase() === 'circular')).length === 0) && 'd-none')}>
              <div className="col-lg-12 d-flex flex-column-reverse flex-lg-row align-items-start">
                <div className="tags">
                  {
                  isLoading
                  ? [1,2,3,4,5,6,7,8,9].map((index) => (
                    <span
                        key={index}
                        className="animate__animated animate__fadeInRight cursor-pointer pill tag skeleton"
                      >
                        Cargando..
                      </span>
                  ))
                  :allBlogData?.tags?.filter(tag => tag.name.toLowerCase() !== 'circular').map((tag, index) =>
                    listActives.find(e => e === tag.id) ? (
                      ""
                    ) : (
                      <span
                        key={index}
                        onClick={() => toggleTag(tag.id)}
                        className="animate__animated animate__fadeInRight cursor-pointer pill tag"
                      >
                        {tag.name}
                      </span>
                    )
                  )}
                </div>
                <div className="searcher d-flex align-items-end">
                  <div className="content-actives">
                    {listActives.map((tag, index) => (
                      <span
                        key={index}
                        onClick={() => toggleTag(tag)}
                        className="animate__animated animate__fadeInRight cursor-pointer pill tag"
                      >
                        {allBlogData?.tags.find(element => element.id === tag).name}
                      </span>
                    ))}
                  </div>
                  <div onClick={() => filterNoveltiesAction()} className="btn btn-white border">FILTRAR</div>
                </div>
              </div>
            </div>
          </div>
        </section>
      <section className="grid-novedades py-lg-0">
        <div className="content-novelties d-flex flex-lg-row flex-column flex-wrap align-items-center justify-content-center">
          {
            (isLoading) ?
              [1,2,3].map((element) => <NoveltyCard skeleton={true}/>
              )
            :''
          }
          {filteredNovelties?.filter(n => n.featured === false && !n.tags.find(element => element.name.toLowerCase() === 'circular')).map((novelty, index) => ( 
            index < 6 &&
            <NoveltyCard novelty={novelty} />
          ))}
          <Featured />
          {filteredNovelties?.filter(n => n.featured === false && !n.tags.find(element => element.name.toLowerCase() === 'circular')).map((novelty, index) => ( 
            index >= 6 &&
            <NoveltyCard novelty={novelty} />
          ))}
        </div>
      </section>
    </>
  )
}

export default connect(
  state => ({
    listActives: state.novelties.listActives,
    novelties: state.novelties.novelties,
    loading_grid: state.novelties.loading_grid,
    loaded_novelties:state.novelties.loaded_novelties,
    filtersNovelties: state.novelties.filtersNovelties,
    grid_novelties: state.novelties.grid_novelties,
  }),
  null
)(Grid)
