import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import "../../../../node_modules/animate.css/animate.css"
import Image from "../../../images/novedades-top.png"
import {
  getDateTime,
  getFeatured,
  getTextShort,
} from "../../../helpers/helper.novelty"
import Slider from "react-slick";
import { NextArrow, PrevArrow } from "../arrows.jsx"
import { Link } from "gatsby"
import { useGetBlogsQuery } from "../../../redux/mediacore/blog"

const Featured = ({ dispatch, novelties }) => {
  const [noveltiesFeatured,setNoveltiesFeatured] = useState([])

  const settingsSlick = {
    dots: false,
    infinite: true,
    speed: 500,
    fade:false,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
};

  const { data: allBlogData, isLoading, isError } = useGetBlogsQuery(26);

  useEffect(() => {
    if(allBlogData?.news){
      setNoveltiesFeatured(getFeatured(allBlogData?.news))
    }
  },[allBlogData])

  return noveltiesFeatured.filter(n => !n.tags.find(t => t.name.toLowerCase() === 'circular')).length > 0 ? (
    <section className="w-100 featured-novedades py-0">
      <Slider {...settingsSlick}>
        {noveltiesFeatured.filter(n => !n.tags.find(t => t.name.toLowerCase() === 'circular')).map((novelty,index) => (
          <div className="d-lg-flex align-items-center w-100 position-relative item-slider">
            <div className="w-50">
              <img className="w-100" src={novelty.image} alt="Novedad destacada imagen de portada" />
            </div>
            <div className="w-50 d-flex flex-column white content">
              <div className="date mb-lg-4">
                {getDateTime(novelty.date_published)}
              </div>
              <h3 className="mb-lg-5">
                {novelty.title}
              </h3>
              <p>{getTextShort(novelty.headline, 400)}</p>
              <Link to={"/novedades/" + novelty.id + "/" + novelty.slug} className="btn-black border px-lg-5 py-lg-2 btn mt-lg-5">
                Leer más
              </Link>
            </div>
          </div>
        ))}
      </Slider>
    </section>
  ) : (
    ""
  )
}

export default connect(
  state => ({
    loading_novelties: state.novelties.loading_novelties,
    novelties: state.novelties.novelties,
  }),
  null
)(Featured)
